.video-item {
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}

.video-item.item img {
  max-width: 180px;
  width: 45%;
}
